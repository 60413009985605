import { booleanAttribute, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LayoutService } from '@services/layout/layout.service';
import { LayoutSize } from '@entities/layout/layout-size.class';
import { ButtonComponent, ColorType } from '@core/components/buttons/button.component';
import { NgIf } from '@angular/common';
import { IconComponent } from '@core/components/icon/icon.component';

@Component({
  selector: 'pd-more-menu',
  standalone: true,
  imports: [MatMenuModule, NgIf, IconComponent, ButtonComponent],
  styles: [':host { display: flex; }'],
  template: `
    <a
      href="javascript:void(0)"
      data-cy="buttonMore"
      *ngIf="inline"
      [matMenuTriggerFor]="menu"
      class="text-primary dark:text-primary-light">
      <pd-icon [icon]="icon"></pd-icon>
    </a>
    <button
      *ngIf="!inline"
      data-cy="buttonMore"
      pd-button
      [loading]="loading"
      [icon]="icon"
      [color]="color"
      [matMenuTriggerFor]="menu"></button>
    <mat-menu #menu="matMenu" xPosition="before">
      <ng-content></ng-content>
    </mat-menu>
  `,
})
export class MoreMenuComponent implements OnChanges {
  @Input()
  public icon: string = 'ellipsis-vertical';
  @Input({ transform: booleanAttribute })
  public inline = false;
  @Input({ transform: booleanAttribute })
  public loading = false;
  @Input({ transform: booleanAttribute })
  public ellipsisOnMobile: boolean = true;
  @Input()
  public color: ColorType = 'secondary';
  public currentLayout: LayoutSize;
  public constructor(private layoutService: LayoutService) {
    this.layoutService.currentBreakpoint$.pipe(takeUntilDestroyed()).subscribe((currentLayoutSize) => {
      this.currentLayout = currentLayoutSize;
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && Object.prototype.hasOwnProperty.call(changes, 'icon')) {
      this.icon = changes['icon'].currentValue ?? (this.inline ? 'ellipsis' : 'ellipsis-vertical');
    }
  }
}
